/* purgecss start ignore */
#logo {
    max-height: 35px;
}
@media (max-width: 500px) {
    #logo {
        max-width: 200px;
    }
}

.navLinks{
    font-size: 0.8rem;
    font-weight: 600;
    margin-right: 1.2em;
    outline: none;
    :hover {
        text-decoration: none !important;
    }
    :after{
        display: none;
    }
}
.link-active {
    text-decoration: none;
    position: relative;
    &::after {
        position: absolute;
        content: '';
        left:8px;
        bottom:9px;
        width:15px;
        height:3px;
        background-color:$seconday-color;
    }
}


.dropdown-menu{
    padding:0 !important;
    border:none;
    border-radius: 0 !important;
    position: absolute;
    top:3.7em;

}
.navbar-light .navbar-toggler {
    border: none;
}
#dropdownLinks{
    font-size: 0.8rem;
    font-weight: 800;
    color: $primary-color;
    display: flex;
    justify-content: space-between;
    padding: 15px  30px;
    &:active{
        background-color: rgba(255,255,255,0.2);
    }
}

.arrowIcon{
    font-size: 1.2rem;
    margin-left:3em;
}

.nav-link{
 display:flex;
    align-items:center;
}
#navDropdown {
    display: flex;
    align-items: center;
    margin-top:2px;
}
.react-link:hover {
    text-decoration: none !important ;
}
.dropdown-item.active, .dropdown-item:active{
    background-color: transparent
}
/* purgecss end ignore */