.circle {
  @include circle(140px, rgba(152, 71, 238, 0.4));
  top: 0%;
  left: -16%;
}
.circlee {
  @include circle(600px, #fe5bac);
  top: 13%;
  right: -45%;
  opacity: 10%;
}
.container {
  z-index: 1;
}

.transparent {
  opacity: 1;
  background: transparent;
  border: 2px solid #34006b;
}
.transparent::placeholder {
  // color: $gray;
  font-weight: bold;
  font-size: 0.8em;
}

@mixin buttons($color) {
  background-color: $color;
}
.log-button {
  @include buttons(#34006b);
}
.fb-login-container {
  width: clamp(150px, 232px, 20em)
}
.fb-button {
  @include buttons(rgba(66, 103, 178, 1));
  .login-div {
    max-height: 1em;
    font-size: 0.6em;
  }
  .scnd-div {
    font-size: 0.9em;
  }

}
.go-button {
  @include buttons(rgba(212, 30, 69, 1));
  .login-div {
    max-height: 1em;
    font-size: 0.6em;
  }
  .scnd-div {
    font-size: 1.3em;
  }
  @media (max-width: 450px){
    margin-top:20px;
  }

}
.italic {
  font-size: 14px;
}
.or-container {
  color: $primary-color;
  border-color: $primary_color;
  .line-div {
    height: 1px;
    background-color: $primary-color !important;
    opacity: 0.2;
  }
  .circle-div {
    border: 1px solid rgba(52, 0, 107, 0.1);
    border-radius: 50%;
  }
}

.forget-circle-translucent {
  @include circle(30rem, $seconday-color);
  left: 7rem;
  opacity: 0.2;
}
.mail-circle-translucent {
  @include circle(30rem, $seconday-color);
  left: 50%;
  top: 0;
  opacity: 0.2;
}
