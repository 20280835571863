// ADD WEBSITE STEP ONE
.aws1-started {
  h6 {
    font-size: 1.1em;
    font-weight: 800;
  }
  p {
    font-size: 0.85em;
    font-weight: 500;
  }
}
.aws1-form {
  label {
    font-size: 0.7em;
    color: $navyBlue;
  }
  i {
    font-size: 0.72em;
    font-weight: 500;
  }
}
.aws1-input {
  font-size: 0.9em;
  font-weight: 600;
  padding: 0.65em;
  color: $primary-color;
  &::placeholder {
    // color: #be98e8;
    font-size: 0.8em;
  }
}
.aws1-accordion-head {
  p {
    font-size: 0.9em;
    font-weight: 800;
  }
}
.aws1-accordion-body {
  p {
    color: $navyBlue;
    font-weight: bold;
    font-size: 0.8em;
  }
  i {
    font-size: 0.75em;
  }
  a {
    font-size: 0.9em;
  }
}
.copy-board {
  border: 1px solid #ecd9ff;
  button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    font-size: 0.85em;
    font-weight: 600;
  }
}
.css-1rhbuit-multiValue {
  background-color: $peachPink !important;
  border-radius: 1em !important;
  font-size: 0.8em;
  color: white !important;
  .css-12jo7m5 {
    color: white !important;
  }
}
.aws2-checkbox {
  margin-top: -1px;
  &:checked[type="checkbox"] {
    background-color: $peachPink !important;
    border: none;
  }
  &:after {
    background-color: $peachPink;
  }
}
.aws2-chips {
  background-color: rgba(218, 98, 125, 0.2) !important;
  cursor: pointer;
  font-size: 0.75em !important;
  font-weight: 600 !important ;
}
.aws2-input {
  &::placeholder {
    font-size: 0.8em;
    font-weight: 500 !important;
    // color: #be98e8;
  }
  &::-webkit-input-placeholder {
    font-weight: lighter;
  }
  &::-moz-placeholder {
    font-weight: lighter;
  }
  &::-ms-input-placeholder {
    font-weight: lighter;
  }
}

//.ga-button {
//  background: url("https://developers.google.com/analytics/images/terms/logo_lockup_analytics_icon_horizontal_black_2x.png");
//}

.css-g1d714-ValueContainer {
  height: 37px;
  overflow-y: scroll !important;
}

.css-1hwfws3 {
  height: 37px;
  overflow-y: auto !important;
}

.1hwfws3
  {
    height:37px;
    overflow-y:scroll !important;
  }

