.order-journey-timeline-circle {
  background: #1EC93A;
  width: 32px !important;
  height: 32px !important ;
  border-radius:50%;
}

.order-journey-timeline-line {
  width: 1px;
  height:100%;
  background: darkgray;
  margin: 0 auto;
}

.rs-btn-icon.rs-btn {
  padding: 10px;
  line-height: 16px;
  display: none;
}

.journey-col-1 {
  min-width: 7em;
  max-width: 7em;
  @media (max-width:450px){
    max-width:5em;
    min-width:5em;
  }
}

.oj-order-number {
  font-size:1.8em;
  font-weight:800;
}
.oj-link {
  font-size:0.9em;
  span {
    font-size:0.7em;
  }
}
.oj-order-date {
  font-size:0.7em;
}
.oj-order-date-div {
  font-size: 0.9em;
  font-weight:500;
}
.oj-headingTitle {
  font-weight:700;
  font-size: 1.2em;
}
.oj-description {
  font-size: 0.8em;
}
.oj-order-details {
  font-size: 0.8em;
}
.oj-md-heading {
  font-size:0.8em;
}
.oj-md-link {
  font-size: 0.8em;
}
.oj-button {
  font-size:0.85em
}