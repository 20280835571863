.sidebar {
  width: 12.5em;
  max-width: 12.5em;
  min-width: 12.5em;
  @media (max-width: 1100px){
    display: none;
  }
}

.sidebar-container {
  position:fixed;
  height:calc(100vh - 3em);
  overflow: scroll;
  width: 9.4em;
  max-width: 12.5em;
  min-width: 12.5em;
}
.wallet-summary {
  margin-top:-4px !important;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.6);
  p {
    font-size: 0.65rem;
    font-weight: 600;
  }
  h2 {
    font-weight: 800;
  }
  h5 {
    font-size: 1rem;
    font-weight: 800;
  }
  button {
    border: none;
    font-size: 0.7rem;
    background-color: $seconday-color !important;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
  }
}

.side-route {
  padding: 1.6vh 0;
  color: $gray;
  font-size: 0.6rem;
  letter-spacing: 0.7px;
  font-weight: 600;
  &:hover {
    color: $primary-color;
  }
  &:active {
    color: $primary-color;
  }
  &:focus {
    color: $primary-color;
  }
  }
.side-route-active {
  color: $primary-color;
  font-size: 0.65rem;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
  &:after {
    position: absolute;
    top: 0;
    right:-2.3em;
    content: "";
    width: 5px;
    height: 100%;
    z-index:99999;
    background-color: $primary-color !important;
  }
}
.side-route-icon {
  margin-right: 10px;
  width: 1.5rem;
  height: 1.5rem;
}

//.nav-item-active{
//  a:hover, a:focus, a:active {
//    text-decoration: none;
//  }
//}