.popup-common-styles {
  color: #141759;
  font-size: 0.8rem;
}

.orderId {
  font-size: 1.3rem;
  font-weight: 800;
}

.popup-links-url {
  font-weight: 600;
  font-size: 0.85rem;
}

.popup-links-url-underline-hover {
  &:hover {
    color: #9847ee !important;
    text-decoration: underline !important;
  }
  &:active {
    color: #9847ee !important;
    text-decoration: underline !important;
  }
  &:visited {
    color: #9847ee !important;
    text-decoration: underline !important;
  }
  &:focus {
    color: #9847ee !important;
    text-decoration: underline !important;
  }
}

.popup-orderdetails-sub-titles {
  font-size: 0.7rem;
}

.anchor-text {
  color: #34006b;
  font-size: 0.85rem;
}

.popup-titles {
  font-size: 0.8rem;
}

.anchor-link-text {
  font-size: 0.9rem;
}

.intructions {
  color: #808080;
  font-size: 0.8rem;
}

.delivered-url-input {
  border: 0.5px solid #ecd9ff;
  outline: none;
  font-size: 0.95rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.delivered-url-input::placeholder {
  color: gray;
}

.reject-reason-input {
  border: 0.5px solid #ecd9ff;
  outline: none;
  font-size: 0.95rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.reject-reason-input::placeholder {
  color: grey;
}

.accpt-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  color: #fff;
  background-color: #78ae62 !important;
  font-weight: 500;
  font-size: 0.9rem;
}

.rjct-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  color: #fff;
  background-color: #d41e45 !important;
  font-weight: 500;
  font-size: 0.9rem;
}

.disabled-accept-btn {
  background-color: #9bac95 !important;
}

.disabled-reject-btn {
  background-color: #d3919f !important;
}

.order-details-text {
  font-size: 0.8rem;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.concern-text {
  font-weight: 600;
  letter-spacing: 0.6px;
  font-size: 0.8em;
  cursor: pointer;
}

p span {
  display: inline-block;
}

.hover-effect-url:hover {
  text-decoration: underline;
}

.fake-input {
  height:40px;
  background-color: rgba(220, 220, 220, 0.3) !important;
  border-radius:5px;
  border: 1px solid lightgrey;
}