.filter-scroll-down{
    width: 350px;
    background-color: #fff !important;
    // height: 25px;
    transition:  all 0.3s ease-in-out 0s;
}

.icon-container{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff !important;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.filter-text{
    font-size: 0.85rem;
}

.clear-all-btn{
    background: none;
    font-size: 0.7rem;
    color: #D41E45;
    outline: none;
}



.categories-filter-scrolldown{
    width: 100%;
    outline: none;
}

.traffic-filter-scrolldown{
    width: 100%;
}

.selected-filter-text{
    font-size: 0.65rem;
    font-weight: 600;
}