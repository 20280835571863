.payments-card {
  min-height:18em;
  min-width: 300px;
  width:100%;
  flex: 1;
  background: #ffffff99;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  @media (max-width: 450px){
    min-width: 100%;
    margin-bottom: 10px;
  }
  @media (max-width: 640px){
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.payment-margin-right {

  margin-right:20px;
  @media (max-width: 640px){
    margin-right: 0;
  }
}
.payments-card-icon {
  position: absolute;
  bottom: -2.2em;
  left: -1em;
  width: 14em;
  height: 14em;
  color: rgba(218, 98, 125, 0.1);
}
.payments-cards-container {
  align-self:center;
  justify-self: center;
  max-width: 1300px;
  width: 100%;
}
.set-primary-checkbox {
  input {
    margin-top: -2px;
    border: 2px solid $primary-color;
    &:checked[type="checkbox"] {
      background-color: $seconday-color !important;
      border: none;
    }
  }
  label {
    font-size: 0.8em;
  }
}
.payments-card-button {
font-size:0.8em
}

.payments-card-heading{
  h5{
    padding:0;
    line-height:1em;
  }
  p{
   font-size:10px
  }
}
.payment-personal-text {
  font-size: 0.7em;
  font-weight: 500;
}
.bank-custom-select {
  margin-right:0 !important;
  margin-left:0 !important;
  div select {
    width:100% !important;
    max-height:38px !important;
  }
  div span {
    max-height:36px !important; ;
  }
}
.payment-card-error{
  font-size:0.8em;
}
.pending-details {
  p{
    font-size: 0.9em;
    padding:5px 0;
  }
}
.card-primaryTag{
  top:0; right:0;
  background-color: $lightPeach !important;
  padding: 7px;
  font-size:0.85em;
  border-bottom-left-radius:8px;
}