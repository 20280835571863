.add-new-preference-title{
    font-size: 1.6rem;
    font-weight: 800;
}

.modal-close-btn{
    background: none;
    color: #808080;
}

// .preferenceNameLabel{
//     font-size: 0.8rem;
// }

.preference-name-input{
    border: solid 1px #ECD9FF;
    padding-top: 12px;
    padding-bottom: 12px;
}

.preference-name-input::placeholder{
    color: #B1B1B1;
    font-size: 0.8rem;
}

.save-apply-btn{
    font-size: 0.95rem;
}

.da-range-slider-bar{
    background-color: #ECD9FF !important;
}

.da-range-slider{
    background-color: #9847EE !important;
    height: 10px;
}

.add-preference-label-theme{
    color: #141759;
    font-size: 0.8rem;
}

.add-preference-inputs-theme{
    color: $primary-color;
    border: solid 1px #ECD9FF;
height: 40px;
}

.add-preference-inputs-theme::placeholder{
    color: gray;

}

.rs-slider-progress-bar{
    background-color: #9847EE !important;
    opacity: 36%;
}

.rs-slider-handle:before{
    background-color: #9847EE !important;
    border: none !important;
    // box-shadow: 0 0 0 8px rgb(52 152 255);
    box-shadow: 0 0 0 8px rgb(152,71,238) !important;
}

.add-preference-checkboxes{
    width: 25px;
    height: 25px;
    border: 5px solid #34006B;
}

.add-preference-checkboxes:checked{
    background-color: #9847EE !important;
}

.ga-validate-subtext{
    font-size: 0.7rem;
}