.guest-post-num{
    font-size: 2rem;
    font-weight: 800;
}

.website-data{
    text-align: left;
}

.website-analytics{
    font-size: 1.4rem;
    font-weight: 800;
}

.website-data-text{
    font-size: 0.6rem;
    text-transform: uppercase;
}

.category-text{
    font-size: 0.6rem;
    font-weight: 700;
    text-transform: uppercase;
}

.wishlist-btns-container button{
    color: white;
    border-radius: 6px;
    border: 0;
    outline: 0;
}

.cart-btn{
    font-weight: 500;
    background-color: #34006B !important;
    letter-spacing: 1.2px;
}


.delete-btn{
    background-color: #D41E45 !important;
    padding: 0 15px;
}

.dlt-icon{
    width:30px;
    height:30px;
    stroke-width: 1.2px;
}