.heroContainer {
  width: 100%;
}

.imgContainer {
  position: relative;
  height: 100%;
}

.heroImg {
  width: clamp(20em, 10vw, 25em);
  margin-left: 3em;
  aspect-ratio: attr(514px) / attr(864px);
}

.testimonial-banner-as {
  // aspect-ratio: attr(954px) / attr(933px) !important;
  // aspect-ratio: 1.022 / 1 !important;
  // width: 5000px;
  // height: 5000px;
  max-width: 100%;
  height: auto;
}

@media (max-width: 450px){
  .heroImg {
    width:60% !important ;
    margin-left:20% !important ;
  }
  .tourBox {
    margin-bottom: -2em;
  }
  .tour-text {
    font-size: 0.9em !important;
    font-weight: 900;
  }
}

@media (max-width: 768px) {
  //.heroImg {
  //  margin-left: 0;
  //  position: relative;
  //}
  .heroImg {
    width:60% !important ;
    margin-left:20% !important ;
  }
  .tourBox {
    margin-bottom: -2em;
  }
}
.tourBox {
  border-radius: 18px;
  width: 100%;
  background-color: #ffffff !important;
  opacity: 0.95;
  position: absolute;
  bottom: 0;
}
.tourBox-separator {
  width: 1px;
  height: 4em;
  background-color: $seconday-color !important;
  opacity: 0.5;
}
.tourBox-float-button {
  font-size: 0.65em;
  right: 2.5em;
  margin-top: 5px;
  border-radius: 20px;
  padding: 5px 17px;
}
.take-tour-text {
  font-size: 0.8em;
}
.tour-text {
  font-size: 1em;
  font-weight: 900;
}
.youtube-container-class {
  display: flex;
  justify-content: center;
  align-items: center;
  height:90vh;
  width: 90vw;
  iframe {
    height:90vh;
    width:90vw;
  }
  @media screen and (max-width: 450px) {
    height:200px;
    width:90vw;
    iframe {
      height:200px;
      width:90vw;
    }
  }
}
.playIcon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: $seconday-color !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding-left: 5px;
}

.VL {
  width: 1px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.home-circle-translucent {
  @include circle(clamp(20em, 45vw, 50em), $darkYellow);
  top: -40px;
  left: -25px;
  opacity: 0.07;
}
.home-circle-translucent-2 {
  @include circle(clamp(20em, 40vw, 40em), $hotPink);
  top: -1em;
  left: -7em;
  opacity: 0.1;
}
.home-circle-1 {
  @include circle(15.5em, $darkYellow);
  top: 24%;
  left: 35%;
  z-index: -1;
  box-shadow: 1px 1px 10px lightgrey;
}
.home-circle-2 {
  @include circle(8em, $seconday-color);
  opacity: 0.3;
  top: -8%;
  left: -6%;
  z-index: -1;
}
.home-circle-3 {
  @include circle(16em, $seconday-color);
  top: -5em;
  right: 3em;
}
.detailsContainer {
  margin-top: 80px;
  position: relative;
  text-align: left;
}

.heroHeader {
  font-size: 2.8rem;
  font-weight: 800;
  color: $primary-color;
  letter-spacing: 2.4px;
  z-index: 1;
}

.helperText {
  font: italic normal normal 30px/37px Montserrat;
  letter-spacing: 1.2px;
  color: $primary-color;
  opacity: 1;
}

.home-ul-header {
  font-weight: 800;
  font-size: 1.4em;
}
.home-checkpoints {
}
.checkpoint-content {
  font-size: 0.9em;
  line-height: 1.2em;
  font-weight: 500;
}
.checkpoint-icon {
  margin-top: -5px;
  margin-right: 7px;
}

ul li {
  text-align: left;
  font: italic normal medium 22px/24px Montserrat;
  letter-spacing: 0.22px;
  color: $primary-color;
}

.cardInfo {
  font-size: 0.7em;
}

.mantraUsers {
  font-size: 0.7em;
  line-height: 1.6em;
  i {
    font-weight: 600;
  }
}

.knowUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  margin-bottom: 30px;
  @media (max-width: 450px){
    padding-top:20px;
    margin-bottom: 30px;
  }
}

.knowUs-item {
  min-width:200px;
  @media (max-width: 450px){
   width:100%;
    display:flex;
    justify-content: center;
  }
}

.knowUs p {
  font: italic normal normal clamp(0.6rem, 2vw, 0.8rem) Montserrat;
  letter-spacing: 0.16px;
  color: $primary-color;
  padding-bottom: 20px;
}
.knowUsDetails h6 {
  font-weight: 600;
  color: $seconday-color;
  width: 200px;
}

.imgHeaderContainer {
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.bgCircle6 {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 30%;
  top: -10px;
  z-index: -1;
}

.imgHeaderContainer img {
  width: 3.5em;
  height: 3.5em;
  margin-right: 75px;
  margin-left: 10px;
}

.enterprises {
 margin-top:20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.becomeMember {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .memberHelperText {
    width: 80%;
    font-size: 0.9em;
    font-weight: normal;
  }
  @media (max-width: 450px){
    margin-top:50px;
  }
}

@media (max-width: 900px) {
  .heroButton {
    width: 100%;
  }
  .heroImg {
    margin-left:20% !important ;
  }
  .knowUsDetails {
    width: 250px;
  }

  .heroButton h1 {
    font-size: 0.8rem;
  }

  .playIcon {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }
  .enterprises h1 {
    font-size: 1.4rem;
    line-height: 30px;
  }
}


.youtubeModal{
  text-align: center;
  margin-top:auto;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: none;
  border-radius: 8px;

}
.home-brand-carousel-container {
  @media (max-width: 450px){
    margin-top:10px !important ;
    margin-bottom: 10px !important;
  }
}