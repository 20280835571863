.header {
  font-size: clamp(1.5rem, 5vw, 2.45em);
  font-weight: 800;
  letter-spacing: 2.4px;
}

.description {
  font-size: clamp(0.75rem, 3vw, 0.9rem);
  letter-spacing: 0.96px;
  line-height: 1.7em;
  font-weight: 500;
}
