.bdCrbText{
    font-size: 12px;
    letter-spacing: 0.56px;
    color: #34006B;
  }
  
  .currentRoute{
    color: #34006B;
    font-weight: 500;
  }
  
  .advertiserRoutes:hover{
      color: #9847EE;
  }

  .publisherRoutes:hover{
      color: #DA627D;
  }