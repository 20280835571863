.keywordUrlContainer{
    border-radius: 10px;
    border: 1px solid  #ecd9ff;
    width: 100%;
}

.keywordsInputContainer{
    margin-right: 33px;
}

.keywordsInputContainer, .urlInputContainer{
    // width: 44%;
    flex: 1;
    margin-left: 16px;
}

.add-input-btn{
    background-color: #9847EE !important;
    color: #fff;
    border: none;
    outline: 0;
    padding: 5px 8px;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: normal;
}