
.tools {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  position: relative;
  h3 {
    text-align: center;
    font-size: clamp(1.2rem, 4vw, 20px);
    font-weight: 800;
    font-style: italic;
    letter-spacing: 1.2px;
    color: $seconday-color;
    text-transform: uppercase;
  }
  .toolsHeader {
    width: 90%;
  }
  .toolsText {
    width: 70%;
  }
	> * + * {
		margin-top: 1.3rem;
	}
}
.advertiser-circle-translucent {
  @include circle(20rem, $hotPink);
  left: 2rem;
  top: 12rem;
  opacity: 0.1;
}
.advertiser-circle-translucent-2 {
  @include circle(30rem, $hotPink);
  right: -10rem;
  top: -20rem;
  opacity: 0.1;
}
.advertiser-circle-translucent-3 {
  @include circle(30rem, $darkYellow);
  right: -13rem;
  top: -15rem;
  opacity: 0.2;
  overflow-x: hidden;
}
.advertiser-circle-translucent-4 {
  @include circle(14rem, $darkYellow);
  opacity: 0.2;
  left: -6rem;
  top: -8rem;
}
.advertiser-circle-translucent-5 {
  @include circle(34rem, $hotPink);
  top: 0;
  left: -12rem;
  opacity: 0.15;
}
.advertiser-circle-1 {
  @include circle(8rem, #ee6c4d);
  top: -3rem;
  right: 32%;
  opacity: 0.5;
}
.advertiser-circle-2 {
  @include circle(18rem, $darkYellow);
  top: -5rem;
  left: -7rem;
}
.advertiser-circle-3 {
  @include circle(8rem, $hotPink);
  left: -3rem;
  top: -3.5rem;
  opacity: 0.5;
}
.advertiser-circle-4 {
  @include circle(24rem, $seconday-color);

  right: -8rem;
  top: -11rem;
}
.advertiser-circle-5 {
  @include circle(8rem, $seconday-color);
  left: 50%;
  top: -3rem;
  opacity: 0.5;
}
.icon-text-point{
  @media (max-width:450px){
    width: 100% !important;
    display: flex;
    justify-content:center;
    align-items: center;
  }
}
