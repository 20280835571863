.contact-faq-header {
  font-size: clamp(2.5em, 4vw, 3.4em);
}
.contact-faq-box {
  border-radius: 12px;
  box-shadow: 0 20px 30px #0000001a;
  background: white;
}
.contact-submit-button {
  padding: 0.8em 4.2em;
  font-size: 1.2em;
}
.contact-us-input {
  padding: 1em !important;
  border-radius: 8px;
}

.contact-us-select{
  padding: 1em !important;
  border-radius: 8px;
  color: gray;
}

.contact-faq-desc {
  font-size: 1.1em;
  line-height: 1.6em;
  padding-right: 2em;
}
.check-faq-text {
  font-size: 0.8rem;
}
.contact-skype-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 12px;
  bottom: 6px;
}
.contact-circle-translucent {
  @include circle(22rem, $hotPink);
  left: -8rem;
  opacity: 0.1;
}
.contact-circle-1 {
  @include circle(16rem, $darkYellow);
  top: 2rem;
  right: -2rem;
}
.contact-circle-translucent-2 {
  @include circle(26rem, $hotPink);
  bottom: -11rem;
  right: -11rem;
  opacity: 0.15;
}
.contactUs-img-container{
  @media (max-width: 450px){
    display:none;
  }
}