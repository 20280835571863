.order-journey-container {
  width: 45%;
  height: 100%;
  background: white;
  position: absolute;
  right: 0;
}
.order-filter-label {

  font-size:0.7em;
  font-weight:500;
  color: $navyBlue;
  padding-bottom:1em;
  display: block;
}
.clear-btn{
  cursor: pointer;
}
.inputs-span {
  height:38px;
  top:1px;
  width: 2.25em;
  background-color: #ECD9FF !important;
  right:1px;
  border-top-right-radius:  3px;
  border-bottom-right-radius: 3px;
  pointer-events: none;
}