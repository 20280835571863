.carouselImg{
    aspect-ratio: attr(2500px) / attr(1408px);
    max-width: 200px!important;
    min-width: 200px!important;
    height: 100px;
    object-fit: contain;
    @media (max-width: 650px){
        max-width: 140px!important;
        min-width: 140px!important;
        height: 70px;
    }  @media (max-width: 380px){
        max-width: 100px!important;
        min-width: 100px!important;
        height: 50px;
    }
}

.rec.rec-arrow{
    border: none;
}

.slider-arrow-icon {
    color:$primary-color;
    height:4em;
    width:4em;
    margin-top:1em;
    stroke-width: 1px;
    &:hover {
        color:$seconday-color;
    }
    @media (max-width: 450px){
     height:2em;
        width:2em;
        margin-top:1em;
    }
}