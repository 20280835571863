.accordion-title {
  // color: $primary-color;
  font-size:1.3em;
  font-weight: 700;
  margin-left: 10px;
}

.accordion-content {
  color: $primary-color;
  font-weight: 500;
  font-size:1.1em;
  line-height:1.7em;
  margin-left: 4em;
  @media (max-width: 450px){
    padding-left:0 !important;
    margin-left:20px !important;
  }
}
.accordion-icon{
  height:20px;
  width :20px;
}