
.textContainer{
    background-color: #BE98E8 !important;
    font-size: 10px;
    border-radius: 5em;
    font-weight: 500;
    color: #fff;
}

.tagTextContainer{
    background-color: white;
    border:1px solid #34006b;
    font-size: 16px;
    border-radius: 5em;
    font-weight: 600;
    color: #34006b;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;
}

.tagTextContainer:hover{
    background-color: #34006b !important;
    color: white !important;
}

.tagTextContainer .selected{
    background-color: #34006b !important;
    color: white !important;
}

// .textContainer:last-child{
//     margin-right: 0;
// }