.iconContainer{
    width: 25px;
    height: 25px;
    position: relative;
    z-index: 1;
}

.popoverContainer{
    width: 650px;
    //height: 160px;
    background-color: #fff !important;
    padding: 10px 12px;
    z-index: 1;
    position: absolute;
    top: -130px;
    left: -315px; 
}

.popoverContainer p{
    font-size: 0.8rem;
    margin-bottom: 5px;
    color: #34006B;
    font-weight: 600;
}

.arrowDown{
    bottom: -10px;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    // margin: 0 auto;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    z-index: 1;
}