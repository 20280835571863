.btnStyle{
    padding: 0.8em 2em;
    color: #fff;
    font-size:0.8em;
    font-weight: 600;
    letter-spacing: 1.6px;
    border: none;
    border-left: 5px solid $seconday-color;
    box-shadow: 0px 0px 20px #34006B1F;
    background: linear-gradient(to left, $primary-color 50%, $seconday-color 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    &:hover{
        background-position: left;
    }
    &:focus {
    background: $seconday-color;
    }

}
