.dashboard-bg{
  background: url('../../assets/background-dashboard.webp');
  background-size: cover;
  background-repeat: no-repeat;
}
.dashboard-main {
  background-color: rgba(255, 255, 255, 0.5);
  min-height: 100vh;
  height: 100%;
}
.table-th {
  font-size: 0.75rem;
}
.table-action {
  font-size: 0.55rem !important;
}
.dashboard-footer {
  justify-self: flex-end;
  align-self: flex-end;
  border-top: 1px solid white;
}

.orders-button {
  font-size: 0.7rem;
}
.dashboard-table-tr {
  td {
    font-size: 0.9em;
    margin: auto;
    align-items: center;
  }
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: rgba(255, 255, 255, 0.5);
  color: var(--bs-table-hover-color);
}
