.footer-bottom-section {
    border-top: 1px solid darkgray;
}

.footer-headings{
    font-weight: 800;
  font-size:1.1em;
}
.footer-links {

        font-weight:bold;
        font-size:0.85em;
        text-decoration: none;
    line-height: 2.5em;
}
.footer-icons {
    margin:10px 13px 10px 0;
    font-size:0.9em;
}
//.footer-bottom-links {
//        font-weight: bold;
//  text-decoration: none;
//}
.footer-desc {
  font-size:0.8em;
  font-weight:500;
}
.footer-logo {
  aspect-ratio: attr(420px) / attr(71px);
  width:15em;
  padding:10px 0;
}
.footer-bottom-text{
  font-size:0.8em;
  font-weight:500;
}
@media (max-width: 500px) {
   .footer-bottom-one {
       margin: auto;
       p{
           font-size:0.72em;
       }
   }
  }