.dashboard-card{
  width:12rem;
  height:9.5rem;
  box-sizing: border-box;
  background-color: rgba(255,255,255,0.4) !important;
  border-radius:15px;
  color:$primary-color;
  p{
    font-size:0.7rem;
    font-weight:600;
    margin:0;
    padding:0;
  }
  h2{
    margin:0;
    padding:0;
    font-weight: 800;
  }
  @media (max-width: 450px){
    width:10.5rem;
  }
}
.dashboard-card-icon{
  width:37px;
  height:37px;
  stroke-width: 1.5px;
}