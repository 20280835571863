.my-website-card {
  background-color: rgba(255, 255, 255, 1) !important;
  box-shadow: 0px 20px 30px #0000001a;
  border-radius: 6px;
  min-width:300px !important;
}
.website-name{
  text-overflow: ellipsis;
  @media (max-width: 450px){
    font-size: 1em;
    text-overflow: ellipsis;
  }
}
.smallText {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0;
}
.messageText {
  color: rgba(128, 128, 128, 1) !important;
  font-size: 11px;
  letter-spacing: 0;
}

.warningIcons {
  min-width:25px;
  min-height:25px;
  color: rgba(237, 131, 78, 1);
  stroke-width: 1.5px;
}

.verifyButton {
  font-size: 0.7em;
  color: rgba(218, 98, 125, 1);
}
.verifiedButton {
  font-size: 0.85em;
  font-weight: 600;
  color: rgba(30, 201, 58, 1);
  text-decoration: none;
}
.statusAcDc {
  font-size: 0.7em;
}
.deactivate {
  color: rgba(212, 30, 69, 1);
}
.activate {
  color: rgba(30, 201, 58, 1);
}

.edit {
  font-size: 0.7em;
}

.disabled {
  color: lightgray !important;
}

.addWebButton {
  background-color: rgba(218, 98, 125, 1) !important;
  border-radius: 8px;
  font-size: 0.9em;
}

.validate-text {
  color: #da627d;
  font-size: 12px;
  @media (max-width: 1000px){
    display:none;
  }
}
