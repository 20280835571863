@import "../colors";
.signup-banner {
    background-color: $primary-color !important;
    h2 {
        font-size:2.1em;
    }
    p{
        font-size:0.8em;

    }
}
.signup-button {
    border:none;
    color:$primary-color;
    background-color:white !important;
    font-weight: bold;
    font-size:0.85em;
    &:hover {
        background-color:lightgray !important;
    }
}