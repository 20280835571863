.searchbar-container {
  padding: 1.2em 2.3em 1.6em 1em;
}
.mp-create-button {
  font-size: 0.6em;
  font-weight:600;
}
.mkpl-search {
  color: $primary-color;
  font-size: 0.9em;
  border: 1px solid #ecd9ff;
  height: 40px;
}
.mkpl-search::placeholder {
  color: gray;
}
.mp-search-element{
  &:hover {
    background-color: #dee0e3 !important;
  }
}
.filter-select-container{
  min-width: 255px;
  height: 40px;
  border: 1px solid #ecd9ff
}

.mkpl-radio {
  width: 16px;
  height: 16px;
}
.filter-scrolldown-slider{
  @media (max-width: 450px){
    min-width:300px;
  }
}