.publisher-process-h5 {
  font-weight: 800;
}
.processes {
  max-width: 230px;
  position: relative;
  padding-left: 1rem;
  h1 {
    font-size: clamp(4rem, 5vw, 5rem);
    font-weight: 800;
    line-height: 80px;
    letter-spacing: 4.8px;
    margin-left: 30px;
    @media (max-width: 450px){
      margin-left:0;
    }
  }
  h2 {
    font-size: clamp(1.3rem, 2.5vw, 2.5rem);
    font-weight: 800;
    line-height: 70px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }
  p {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    font-weight: 800;
    margin-top: -15px;
    line-height: 1em;
    letter-spacing: 1px;
  }
  .publisher-process-circle {
    @include publisher-circle(60px);
    top: 0;
    left: 40px;
    opacity: 0.44;
    @media (max-width: 450px){
      left:0;
    }
  }
}
.memberContainer {
  margin-top: 100px;
  margin-bottom: 50px;
}
//.dashboardImage {
//  position: relative;
//}
.dsbdImg1 {
  width: 90%;
}
.dsbdImg2 {
  width: 90%;
}
.dsbdImg3 {
  width: 90%;
}
.dsbdImg4 {
  width: 90%;
}

@media only screen and (max-width: 900px) {
  .publisherContainer {
    .publisherProcess h1 {
      width: 100%;
    }
    .publisherProcess p {
      width: 100%;
    }
    .memberContainer {
      .bgCircle9 {
        top: 0;
        left: 0;
      }
    }
  }
}
.publisher-card {
  div {
    h1 {
      width: 100% !important;
      font-size: clamp(0.85rem, 2vw, 1.2rem);
    }
    p {
      width: 100%;
      font-size: clamp(0.5rem, 1.8vw, 0.8rem);
    }
  }
}
.publisher-circle-translucent {
  @include circle(20rem, $hotPink);
  opacity: 0.15;
  top: 10rem;
  left: -2rem;
}
.publisher-circle-translucent-2 {
  @include circle(30rem, $hotPink);
  top: -18rem;
  right: -12rem;
  opacity:0.15;
}
.publisher-circle-translucent-3{
  @include circle(18rem, $hotPink);
  top:-2rem;
  left:8rem;
  opacity: 0.2;
}
.publisher-circle-translucent-4{
  @include circle(30rem, $darkYellow);
  top:-10rem;
  right:-10rem;
  opacity: 0.2;
}
.publisher-circle-translucent-5{
  @include circle(12rem, $darkYellow);
  top:-4rem;
  left:-6rem;
  opacity: 0.2;
}
.publisher-circle-translucent-6{
  @include circle(30rem, $hotPink);
  top:-4rem;
  left:-6rem;
  opacity: 0.2;
}
.publisher-circle-translucent-7{
  @include circle(30rem, $hotPink);
  bottom:-20rem;
  left:-10rem;
  opacity: 0.1;
}
.publisher-circle-1 {
  @include circle(8rem, #EE6C4D);
  top:-3rem;
  right:33%;
  opacity: 0.6;
}
.publisher-circle-2 {
  @include circle(16em, $darkYellow);
  top:-2rem;
  left:-8rem;
}
.publisher-circle-3{
  @include circle(8rem, $peachPink);
  top:-3rem;
  left:-3rem;
  opacity: 0.5;
}

.publisher-circle-4{
  @include circle(24rem, $seconday-color);
  top:-10rem;
  right:-10rem;
}


.process-arrow {
  position: absolute;
  top: 50%;
  height: 32px;
  width: 32px;
  color: $seconday-color;
}
