.invoice-item-container {
  background-color: #f5f7fa;
  border-radius: 0.25rem;
  border: 0.0625rem solid transparent;
  border-top-color: #b7bcbf26;
  box-shadow: 0 0.125rem 0.25rem #00000029;
  padding: 1.5rem;
  position: relative;
  text-align: initial;
  margin-bottom: 1rem;
}
.invoice-form-container {
  padding-right: 2.75rem;
  padding-left: 2.75rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.form-floating > .form-control {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  border: 0.0625rem solid #909697;
  border-radius: 0.25rem;
}
.inv-items-container {
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #cbd2d6;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  margin-top: 0.5rem;

}

.inv-item-option::after {
  border: 0 !important;
}

.dropdown-menu {
  box-shadow: 0 4px 8px 0 #00000033;
  transition: 0.3s;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-item.active, .dropdown-item:active {
  color: black !important;
}

input#Quantity:disabled {
  background-color: #fff !important;
  opacity: 1;
}
input#tax:disabled {
  background-color: #fff !important;
  opacity: 1;
}

.dashboard-inv-container {
  padding-left: 1rem;
  padding-right: 1rem;
}