.cardContainer{
    height: 95%;
    border-radius: 10px;
    box-shadow: 2px 2px 5px #F0E3F9;
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.cardImgContainer{
    width: 100px;
    height: 90px;
    padding-left: 20px;
    padding-top: 15px;
}
.cardImage{
    width:100px;
}
.cardHeader{
    font-size: clamp(1rem, 2vw, 1.1rem);
    font-weight: bold;
    letter-spacing: 0px;
    color: #141759;
    
}
.card-text-container{
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 20px;

}
.card-text-container p{
    font-size: clamp(0.75rem, 1vw, 0.85rem);
    color: #141759;
}

.circle-icon-container{
    position:relative;
    width: 75px;
    height: 75px;
}
.cardCircle{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    margin-top: 10px;
}