.reports-text{
    font-size: 1.7rem;
}
.chart-container {
    width:calc(50% - 10px);
    @media (max-width: 950px){
        width:calc(100% - 10px);
    }
    @media (max-width: 450px){
        width:100%;
    }
}
.chartSize {
    width:100%;
    height:20em;
    @media (max-width: 450px){
        width:calc(100% - 10px);
        height:14em;
    }
}