.cw-circle-translucent {
  @include circle(20rem, $hotPink);
  opacity: 0.15;
  top: 10rem;
  left: -1rem;
}
.cw-circle-translucent-2 {
  @include circle(30rem, $hotPink);
  top: -14rem;
  right: -12rem;
  opacity:0.15;
}
.cw-circle-translucent-3 {
  @include circle(30rem, $hotPink);
  bottom:-15rem;
  left: -12rem;
  opacity:0.15;
}.cw-circle-translucent-4 {
  @include circle(30rem, $darkYellow);
  top: -14rem;
  right: -12rem;
  opacity:0.15;
}
.cw-circle-1 {
  @include circle(8rem, #EE6C4D);
  top:0;
  right:33%;
  opacity: 0.6;
}
.cw-circle-2 {
  @include circle(16em, $darkYellow);
  top:-2rem;
  left:-9rem;
}
.cw-circle-3 {
  @include circle(16em, $darkYellow);
  top:-5rem;
  left:-6rem;
  opacity:0.4;
}