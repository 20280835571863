.ourStoryContainer{
    padding-right: 7%;
    padding-left: 7%;
}

.storyImg{
    width: 100%;
}

.story-circle-translucent {
    @include circle(24em, $hotPink);
    opacity: 0.1;
    left:-12em;
    top:-12em;
    z-index: -1;
}

.leftStory{
    margin-top: 3em;
    font: normal normal normal 1.2em Montserrat;
    letter-spacing: 0.96px;
    line-height: 1.7em;
    color: $primary-color;
    opacity: 1;
}

.rightStory {
    margin-top: 3em;
}
.rightStory ul{
    margin-top: 2.4em;
    li{
        margin-bottom:1.2em;
    }
}

.storyContainer{
    position: relative;
    min-height: 22em;
}

.quote-left {
    position: absolute;
    top:40px;
    left:-10px;
}

.story-together-heading{
    font-weight:800;
    font-size:clamp(1.5rem, 4vw, 2.4rem);
    line-height:1.5em;
    width:85%;
}



.benefitsContainer{
    padding-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 450px){
        padding-top:0;
    }
}

.benefitsContainer h1{
    width:75% !important;
}

.statementContainer{
    margin-top:150px;
    margin-bottom:120px;
    position: relative;
    @media (max-width: 450px){
        margin-top:30px;
        margin-bottom:50px;
    }
}

.statementContainer-header{
    font-size:clamp(0.7rem, 3.2vw, 1rem);
    letter-spacing: 0.8px;
    color: #D41E45;
}


.storyCard {
    div {
        h1 {
            width:100% !important;
            font-size:clamp(0.85rem, 2vw, 0.95rem);
        }
        p{
            font-size:clamp(.5rem,1.8vw,0.7rem)
        }
    }
}

.story-circle-translucent-2{
    @include circle(30rem, $hotPink);
    opacity: 0.1;
    top:-10em;
    right:-12em;
}
.story-circle-translucent-3{
    @include circle(34rem, $hotPink);
    opacity: 0.1;
    left:-15em;
    top:-2em;
}
.story-circle-1{
    @include circle(18em, $darkYellow);
    top: 5%;
    left: -7%;
    opacity:0.9;
    z-index: -1;
}

.story-circle-2{
    @include circle(8em, $seconday-color);
    right: 8rem;
    bottom: 37%;
    opacity:0.9;
    z-index: -1;
}
.story-circle-3{
   @include circle(8em, $seconday-color);
    opacity: 0.8;
    top:-1em;
    right: 0;
    z-index: -1;
}

@media (max-width: 500px) {
    .quote-left {
        display: none;
    }
    .benefitsContainer h1{
        width:100% !important;
    }
}