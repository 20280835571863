.faq-lines-container > * {
	margin: 0;
}
.faq-line1 {
  font-size: clamp(1.4em, 2.5vw, 2em);
  font-weight: 600;
  margin-top: -20px;
	margin-bottom: -18px;
}
.faq-line2{
  font-size: clamp(2em, 3vw, 3em);
  font-weight: 700;
}
.faq-image {
  max-width: 70%;
  margin-left:60px;
  margin-top:-20px;
}

.faq-heading {
	font-size: 1.4em;
	font-weight: 600;
	margin-bottom: -10px;
}

.faq-heading-2 {
  font-size: clamp(2em, 3vw, 3em);
  font-weight: 700;
}

.faq-tab-selected {
  color: $primary-color !important;
  font-weight: 700;
  //border-bottom:5px solid red !important;
  //border-bottom-width:thin;
  &::after {
    content: "";
    display: block;
    margin: 0;
    width: 71%;
    padding: 0;
    margin-left:-1px;
    border-bottom: 5px solid #D41E45;
    margin-bottom:-9px;
  }
}
.react-tabs__tab {
  padding-left:0 !important;
  padding-right:30px !important;
}
@media (max-width: 500px) {
  .react-tabs__tab-list {
    margin: auto;
    font-size:0.7em;

    text-align: center;
  }
}
.react-tabs__tab {
  color: #808080;
  font-weight: 700;
  @media (max-width:500px){
    padding-right:12px !important;
  }
}

.faq-circle-translucent {
  @include circle(20rem, $hotPink);
  opacity: 0.1;
  top:-1rem;
}
.faq-circle-translucent-2 {
  @include circle(26rem, $hotPink);
  right:-8rem;
  top:18rem;
  opacity:0.15;
}
.faq-circle {
  @include circle(8rem, $seconday-color);
  right:-7rem;
  top:13rem;
}