.bullet{
  color: $primary-color;
}
.icon-div{
  margin-right: 0;
  padding-right: 0;
}
.points-div{
  margin-left: 0;
  padding-left: 0;
}
.first-circle{
  @include circle(150px, rgba(152,71,238,0.4));
  top: -10%;
  left: -16%;
  z-index: -1;
}
.second-circle {
  @include circle(600px,rgba(254, 91, 172, 0.1));
top: 15%;
  right: -45%;
}
