.price-text {
  text-transform: uppercase;
  font-size: 0.85rem;
}

.total-price {
  font-weight: 800;
}

.place-btn {
  background-color: #34006b !important;
  color: #fff;
  border: none;
  outline: 0;
  font-size: 1.3rem;
  text-transform: uppercase;
  padding: 0 34px;
  font-weight: 500;
}

.label-text {
  font-size: 0.82rem;
}
.content-writing-input {
  padding: 6px 12px;
  border: 1px solid #ecd9ff;
}

.content-writing-input::placeholder {
  // color: #BE98E8;
  font-size: 0.95rem;
}

.content-writing-dropdown {
  padding: 10px 5px;
  border: 1px solid #ecd9ff;
}

.content-writing-dropdown option {
  font-size: 0.95rem;
}
