.faded-image {
  height: 80px;
  width: 80px;
  border-radius: 50%;
	background-color: $lavender;
  background-size: cover;
  background-position: center;
  margin-right:-10px;
  transition-duration: 0.5s;
  z-index: -1;
  @media (max-width: 450px){
    height:65px;
    width: 65px;
  }
}
.second-faded-element {
  height: 60px;
  width: 60px;
  margin-right:0 !important;
  margin-left:0 !important;
  z-index:-2;
  @media (max-width: 450px){
    height:50px;
    width: 50px;
  }
}
.focus-image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
	background-color: $lavender;
  background-size: cover;
  background-position: center;
  margin-right:-10px;
  transition-duration: 0.5s;
  z-index: -1;
  box-shadow: -2px 2px 18px 6px rgba(52,0,107,0.63);
  -webkit-box-shadow: -2px 2px 18px 6px rgba(52,0,107,0.63);
  -moz-box-shadow: -2px 2px 18px 6px rgba(52,0,107,0.63);
  @media (max-width: 450px){
    height:75px;
    width: 75px;
  }
}

.bubble {
  padding-bottom:30px;
  height:250px;
  min-width: 200px;
  background: rgba(255,255,255,0.5);
  display: block;
  margin: 0 auto;
  border-radius: 10px;
  margin-top: 50px;
  font-size: 1em;
  text-align: center;
  z-index: 1;
  @media (max-width: 450px){
    max-width:350px;
    height:370px;
  }
}


.pointer-1{
  display: inline-block;
  margin: 0 5px;
  vertical-align: middle;
  transform: rotate(180deg);
    width: 60px;
    height: 30px;
    border-bottom: solid 30px rgba(255,255,255,0.5);
    border-left: solid 30px transparent;
    border-right: solid 30px transparent;

}