.small-circle {
  @include circle(180px, rgba(152, 71, 238, 0.4));
  top: 0;
  left: -10%;
  z-index: -1;
}
.small-circle-pref {
  @include circle(180px, rgba(152, 71, 238, 0.4));
  top: -4.5em;
  left: -3.5em;
  z-index: -1;
}
.sub-button {
  background-color: rgba(52, 0, 107, 1) !important;
  border: 1px solid $primary-color;
  color: white !important;
  border-radius: 0.375rem;
  &:active,
  &:hover &:focus {
    background-color: $seconday-color !important;
    border: 1px solid $seconday-color;
    color: white !important;
  }
}

.sub-button:hover {
 background-color:  rgba(52, 0, 107, 1) !important;
}


.sub-button:checked + .sub-button:focus,
.sub-button:active + .sub-button:focus,
.sub-button:focus,
.sub-button:hover {
  box-shadow: rgba(52, 0, 107, 1) !important;
}



.big-circle {
  width: 700px;
  height: 700px;
  border-radius: 100%;
  background: rgba(254, 91, 172, 0.1);
}
.small-lines {
  letter-spacing: 0px;
  font-size: 0.8em;
}
.regImg {
  object-fit: contain;
  max-height: 90%;
  @media (max-width: 750px) {
    max-height: 500px;
  }
}
