$primary-color: #34006b;
$seconday-color: #9847ee;
$hotPink: #fe5bac;
$lightGreen: #86c28b;
$lavender: #9847ee;
$lightLavender: #b181e3;
$lightBlue: #567ebb;
$yellow: #faca66;
$orange: #d15c57;
$darkYellow: #e2af30;
$peachPink: #da627d;
$gray: #808080;
$navyBlue: #141759;
$lightPink: #da627d33;
$lightPeach: #da627d99;
