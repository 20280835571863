.marketplace-element-container {
  padding: 10px;
  flex: 1;
  min-width: calc(50% - 10px);
  max-width: calc(50% - 10px);
  @media (max-width: 550px){
    min-width:100%;
    margin-right:10px;
  }
}
.mpe-categories-container {
  flex: 1
 //width: calc(100% - 240px)
}
.mpe-info-container{

}

.mpe-right-border {
  border-right : 1px solid darkgray;
  margin-right:10px;
  @media (max-width:500px) {
    min-width:100% !important;
    max-width: 100%;
    display: flex;
    justify-content:space-between;
    border-right:0;
  }
    max-width:auto !important;
}

.mpe-center-mobile {
  @media (max-width: 450px){
    width:100%;
    display:flex;
    justify-content: center !important;
    margin-top:10px;
  }
}
.mpe-wishlist {
  background-color:rgba(0,0,0,0.1) !important;
  width:1.6em;
  height:1.6em;
  border-radius:50%;
}
.mpe-divider {
  height:40px;
  width:1px;
  background-color: black !important;
}
.mpe-sitename {
  font-size:0.9em;
  font-weight:600;
}
.mpe-stats, .mpe-amount {
  p {
    font-size: 0.6em;
    margin:0;
    margin-bottom:2px;
    font-weight:700;
  }
}

.mpe-stats-amount {
  font-size:30px;
}
.mpe-amount-text {
  font-size:1.5em;
}
.mpe-checkout-button {
  font-size:0.8em;
}
.mpe-addToCart {
  font-size:0.7em
}