.cursor-pointer:hover {
  cursor: pointer;
}

.bg-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.tooltip-border{
  border:1px solid #d5d5d5 !important;
  &::before{
  }
}

.back-to-top-button {
  bottom: 30px ;
  right: calc(50% - 20px) ;
  z-index: 999
}

/* pretty radio */
 label > input[type="radio"] {
  display: none;
}
 label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: center;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
} label > input[type="radio"]:checked + *::before {
  background: radial-gradient($seconday-color 0%, $seconday-color 40%, transparent 50%, transparent);
  border-color: $primary-color;
}




.input-range__label--min,
.input-range__label--max {
  display: none;
}

.input-range__track {
  background: lightgray !important;
}
.input-range--disabled .input-range__track {
  background: #d7c2ed !important;
}
.input-range__track--active {
  background: $seconday-color !important; }

.input-range__slider {
  background: $seconday-color !important;
  border: 1px solid $seconday-color !important ;
  height:20px !important;
  width:20px !important;
  top:-2px !important;
}

.input-range--disabled .input-range__slider {
  top:-2px !important;
  background: #d7c2ed !important;
  border: 1px solid #d7c2ed !important;
  height:20px !important;
  width:20px !important;
  box-shadow: none;
  transform: none;
}
.input-range__label-container {
  color:$primary-color !important;
  font-weight: 600
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

///////
/* purgecss start ignore */
.rs-drawer-wrapper {
  position: fixed;
  z-index: 1050;
  top: 0;
}
.rs-drawer {
  display: none;
  overflow: hidden;
  position: fixed;
  z-index: 1050;
  box-shadow: var(--rs-drawer-shadow);
  outline: 0;
}
.rs-drawer-open.rs-drawer-has-backdrop {
  overflow: hidden;
}
.rs-drawer-left, .rs-drawer-right {
  top: 0;
  height: 100%;
}
.rs-drawer-top, .rs-drawer-bottom {
  width: 100%;
}
.rs-drawer-full.rs-drawer-top .rs-drawer-content, .rs-drawer-full.rs-drawer-bottom .rs-drawer-content {
  height: 100%;
}
.rs-drawer-right {
  right: 0;
}
.rs-drawer-left {
  left: 0;
}
.rs-drawer-top {
  top: 0;
}
.rs-drawer-bottom {
  bottom: 0;
}
.rs-drawer-open .rs-drawer {
  overflow: visible;
}
.rs-drawer-dialog {
  position: relative;
  width: 100%;
  height: 100%;
}
.rs-drawer-shake .rs-drawer-dialog {
  animation: 0.3s linear shakeHead;
}
.rs-drawer-content {
  position: absolute;
  background-color: var(--rs-bg-overlay) !important;
  outline: 0;
  width: 100%;
  height: 100%;
}
.rs-drawer-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index:1050 ;
  background-color: var(--rs-bg-backdrop) !important;
}
.rs-drawer-backdrop.rs-anim-fade {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
.rs-drawer-backdrop.rs-anim-in {
  opacity: 1;
}
.rs-drawer-header {
  position: relative;
  padding: 20px 40px 20px 60px;
  border-bottom: 1px solid var(--rs-border-primary);
  display: flex;
}
.rs-drawer-header .rs-drawer-header-close {
  position: absolute;
  left: 15px;
  top: 23px;
}
.rs-drawer-title {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  color: var(--rs-text-heading);
  font-weight: normal;
  font-size: 16px ;
  line-height: 36px;
}
.rs-drawer-actions {
  flex-shrink: 0;
  text-align: right;
  border-top: none;
  margin-left: auto;
}
.rs-drawer-title ~ .rs-drawer-actions {
  margin-left: 10px;
}
.rs-drawer-actions .rs-btn + .rs-btn {
  margin-left: 10px;
  margin-bottom: 0;
}
.rs-drawer-actions .rs-btn-group .rs-btn + .rs-btn {
  margin-left: -1px;
}
.rs-drawer-actions .rs-btn-block + .rs-btn-block {
  margin-left: 0;
}
.rs-drawer-body {
  position: relative;
  padding: 30px 60px;
  height: 100%;
  overflow: auto;
}
.rs-drawer-header + .rs-drawer-body {
  height: calc(24%);
}
.rs-drawer-body-close {
  position: absolute;
  left: 15px;
  top: 25px;
}
.rs-drawer-header ~ .rs-drawer-body .rs-drawer-body-close {
  display: none;
}
.rs-drawer-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.rs-drawer-footer {
  text-align: right;
  border-top: none;
  margin: 0 ;
}
.rs-drawer-footer .rs-btn + .rs-btn {
  margin-left: 10px;
  margin-bottom: 0;
}
.rs-drawer-footer .rs-btn-group .rs-btn + .rs-btn {
  margin-left: -1px;
}
.rs-drawer-footer .rs-btn-block + .rs-btn-block {
  margin-left: 0;
}
/* purgecss end ignore */

.mobile-wrap {
  @media (max-width: 450px){
    flex-wrap: wrap !important ;
  }
}

.blink {
  animation: blinker 1s step-end infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
input {
  color: $primary-color !important;
}


//react-select
.css-g1d714-ValueContainer::-webkit-scrollbar {
  display: none;
}
.css-155xv7m-control::-webkit-scrollbar {
  display: none;
}

.hide-on-responsive {
  @media (max-width: 750px){
    display:none;
  }
}
.drawer-close-button {
  display: none;
  @media (max-width:450px){
    display:block
  }
}

.drawer-responsive {
  @media (max-width: 450px) {
    width:350px !important ;
  }
}
.custom-date-input{
  @media (max-width:500px) {
    min-width:250px !important;
  }
}
.text-wrap-on-desktop{
  @media (min-width:900px) {
    white-space: normal !important;
  }
}
.border-lg-end{
  @media (min-width:750px){
    border-right: 1px solid gray;
  }
}