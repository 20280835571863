@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~bootstrap/scss/bootstrap";
@import "./colors";
@import "./Drawer";
@import "./mixins";
@import "./utils.scss";
@import "./components/NavBar";
@import "./components/PrimaryButton";
@import "./components/HomeComponent";
@import "./components/OurStoryComp";
@import "./components/Card";
@import "./components/SignupBanner";
@import "./components/footer";
@import "./components/PublisherComp";
@import "./components/AdvertiserComp";
@import "./components/Typography";
@import "./components/Accordion";
@import "./components/HowItWorksCard";
@import "./components/CarouselComp";
@import "./pages/FAQPage.scss";
@import "./pages/ContactUs.scss";
@import "./pages/LoginPage.scss";
@import "./pages/RegisterPage.scss";
@import "./pages/SignupPage";
@import "./pages/ContentWritingPage";
@import "./components/TestimonialSlider.scss";
@import "./components/InputPassword.scss";
@import "./components/sidebar";
@import "./components/DashboardCard";
@import "./components/UserNav.scss";
@import "./pages/Dashboard";
@import "./pages/PaypalInvoice";
@import "./components/MyWebsitePage";
@import "./components/AddWebsitePage";
@import "./components/Chips";
@import "./components/Wishlist";
@import "./components/OrderContentWritingPage";
@import "./components/CartCard";
@import "./components/ChipCard";
@import "./components/KeywordUrlInput";
@import "./components/CustomTooltip";
@import "./components/InScreenLoader";
@import "./pages/AddWebsitePage.scss";
@import "./pages/MyOrders";
@import "./components/OrderJourney";
@import "./components/OrderActionPopups.scss";
@import "./components/BreadCrumbs.scss";
@import "./components/AddNewPreferenceModal";
@import "./components/FilterScrollDown";
@import "./components/Reports";
@import "./pages/_Marketplace.scss";
@import "./components/_MarketplaceElement";
@import "./pages/Billing&Funds";
@import "./pages/PaymentsPage";
@import "./components/PreferenceList";

html {
  width: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: "Montserrat" -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  background-size: cover;
  background-position: top;
  overflow-x: hidden;
  background: #d7c2ed url("../assets/background.webp") top left no-repeat;
}
body::-webkit-scrollbar {
  display: none;
}
img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;

  /* Safari seems to support, but seems deprecated and does the same thing as the others. */
  image-rendering: -webkit-optimize-contrast;
}
$enable-negative-margins: true;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:focus,
input.form-control:focus,
select.form-select:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.text-theme {
  color: $primary-color !important;
}
.bg-theme {
  background-color: $primary-color !important;
}
.bg-theme:checked + .bg-theme:focus,
.bg-theme:active + .bg-theme:focus,
.bg-theme:active:focus,
.bg-theme.active:focus,
.bg-theme.show:focus {
  background-color: $primary-color !important;
}

.bg-theme-minor {
  background-color: $seconday-color !important;
}
.bg-theme-minor:checked + .bg-theme-minor:focus,
.bg-theme-minor:active + .bg-theme-minor:focus,
.bg-theme-minor:active:focus,
.bg-theme-minor.active:focus,
.bg-theme-minor.show:focus {
  background-color: $seconday-color !important;
}

.bg-peachPink {
  background-color: $peachPink !important;
}

.bg-peachPink:checked + .bg-peachPink:focus,
.bg-peachPink:active + .bg-peachPink:focus,
.bg-peachPink:active:focus,
.bg-peachPink.active:focus,
.bg-peachPink.show:focus {
  background-color: $peachPink !important;
}

.bg-lightPink {
  background-color: $lightPink !important;
}

.bg-lightPeach {
  background-color: $lightPeach !important;
}
.text-theme-minor {
  color: $seconday-color !important;
}

.text-peachPink {
  color: $peachPink !important;
}
.text-peachPink-hover {
  &:hover {
    color: $peachPink !important;
  }
}

.heading-large {
  width: 87%;
  font: normal normal 800 clamp(1.5rem, 5vw, 2.9rem) Montserrat;
  letter-spacing: 2.4px;
}
.fw-semiBold {
  font-weight: 500;
}
.fw-boldest {
  font-weight: 900;
}
@media (min-width: 500px) {
  .overflow-y-hidden {
    overflow-y: hidden;
  }
}

.heading-mid {
  width: 87%;
  font: normal normal 800 clamp(1rem, 3.5vw, 1.8rem) Montserrat;
  letter-spacing: 2.4px;
  opacity: 1;
}

.vh-75 {
  height: 75vh;
}
.c-ptr {
  cursor: pointer !important;
}

.border-lavender {
  border-color: $lavender !important;
}

.form-check-input:checked {
  background-color: $seconday-color !important;
  border-color: $seconday-color !important;
}
.table > :not(caption) > * > * {
  padding: 0.75rem !important;
}
.nav-reduced-height {
  height: calc(100vh - 78px);
}

.reduced-width {
  max-width: calc(100vw - 18em);
}

textarea {
  resize: none;
}

.hover-underline-url {
  &:hover {
    text-decoration: underline;
  }
}

.underline {
  text-decoration: underline !important;
}

.underline-on-hover:hover {
  text-decoration: underline !important;
}

input[type="file"] {
  opacity: 0;
}

.no-scrollbar {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-select-style {
  color: $primary-color;
  font-size: 0.9em;
  border: 1px solid #ecd9ff;
  width: 250px;
  height: 40px;
}

.custom-select-style:disabled {
  background-color: #efefef4d !important;
}

.secondary-on-hover:hover {
  color: $seconday-color !important ;
}

.rs-drawer-body::-webkit-scrollbar {
  display: none !important ;
}
.rs-drawer-right::-webkit-scrollbar {
  display: none !important ;
}
// snackbar customization .

.Toastify__progress-bar--info {
  background-color: $seconday-color !important;
}
.Toastify__progress-bar--success {
  background-color: $seconday-color !important;
}
//
//@media only screen and (max-width:1440px) {
//  .zoom-1 {
//    border :5px solid red;
//    zoom: 90%;
//  }
//}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

//.Modal {
//  height:200px;
//  width:300px;
//}

.ga-validate-tooltip-text {
  font-size: 16px !important;
}

.marketplace-free-text {
  // background: pink !important;
  @media (max-width: 500px) {
    max-width: 400px;
  }
}

.popup-spacing-override {
  margin-top: 8px !important;
}

.addWebButton:hover,
.addWebButton:active,
.addWebButton:focus {
  background-color: $peachPink;
}
.bg-peachPink:hover,
.bg-peachPink:active,
.bg-peachPink:focus {
  background-color: $peachPink !important;
}
.btn {
  border: none !important;
}
.btn:hover,
.btn:active,
.btn:focus {
  border: none !important;
}

.btn-check:checked + .btn:focus,
.btn-check:active + .btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.show:focus {
  box-shadow: none !important;
}

.bg-peachPink-check:checked + .bg-peachPink:focus,
.bg-peachPink-check:active + .bg-peachPink:focus,
.bg-peachPink:active:focus,
.bg-peachPink.active:focus,
.bg-peachPink.show:focus {
  background-color: $peachPink;
}

.bg-theme-minor:hover,
.bg-theme-minor:active,
.bg-theme-minor:focus {
  background-color: #9847ee;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #34006b !important;
}

.nav-pills .nav-link {
  color: #34006b !important;
  text-decoration: none !important;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: white !important;
}

.post-tag {
  background-color: #9847ee;
  font-size: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0px 5px 0px 5px;
  height: 18px !important;
}

.text-danger {
  color: #d80000 !important;
}