.usernav-container {
  @media (max-width: 1100px) {
    display: none;
  }
}
.usernav-mobile {
  @media (min-width: 1100px) {
    display: none;
    border: 1px solid red;
  }
}

.custom-btn-nav {
  display: none;

  @media (min-width: 600px) and (max-width: 1100px) {
    display: block;
  }
}

.toggle-container {
  p {
    font-size: 0.8rem;
  }
}

.user-nav-icon {
  height: 1.5em;
  width: 1.5em;
  margin: 0 10px;
  stroke-width: 1.5px;
  color: $primary-color;
}

.user-nav-avatar {
  height: 30px;
  width: 30px;
  margin: 0 10px;
  border-radius: 50%;
}

.user-nav-name-avatar {
  height: 1.9em;
  width: 1.9em;
  margin: 0 10px;
  border-radius: 50%;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.usernav-type {
  font-size: 0.8rem;
}

.user-nav-badge {
  position: absolute;
  background-color: #d41e45 !important;
  border: 1px solid white;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  font-weight: 600 !important;
  top: -3px;
  right: 0;
}
.user-nav-avatar-badge {
  @include circle(10px, lightgreen);
  top: 0;
  right: 5px;
  z-index: 99;
}
.user-nav-toggle.react-toggle--checked .react-toggle-track {
  background-color: #ffffff;
  box-shadow: 2px 5px 9px 0 rgba(128, 128, 128, 0.5);
}
.react-toggle-track {
  background-color: white !important;
  box-shadow: 2px 5px 9px 0 rgba(128, 128, 128, 0.5);
}
.react-toggle--checked .react-toggle-thumb {
  border: none;
  background-color: $peachPink !important;
}
.react-toggle-thumb {
  border: none !important;
  background-color: $seconday-color !important;
}

.dropdown-menu-container {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 32px;
  right: 8px;
  z-index: 3;
  background: white;
  &:after {
    content: "";
    position: absolute;
    top: -8px;
    z-index: 3;
    right: 3px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
  }
}
.notification-dropdown-menu {
  margin: 10px;
  height: 380px;
  overflow-y: scroll;
}

.profile-menu-container {
  position: absolute;
  top: 36px;
  right: 8px;
  z-index: 3;
  background: white;
  &:after {
    content: "";
    z-index: 3;
    position: absolute;
    top: -8px;
    right: 7px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
  }
}
.profile-menu {
  div {
    white-space: nowrap;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }
  }
}

.notification-menu-item {
  border-bottom: 1px solid lightgray;
  p {
    line-height: 1.3em;
  }
  div {
    font-size: 10px;
  }
}
.usernav-pagetitle {
  @media (max-width: 1190px) {
    font-size: 1.5em !important;
  }
}
