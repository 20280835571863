.mediumText {
  font-size: 0.7em;
  font-weight: bold;
}
.secondary-text {
  font-size: 0.7em;
  font-weight: bold;
  color: rgba(128, 128, 128, 1);
}
.second-div {
  background-color: rgba(112, 112, 112, 1) !important;

  width: 10px;
}
.this::placeholder {
  font-weight: bold;
  //   color: #34006b;
}
.checkout-bttn {
  background-color: #34006b !important;
  font-size: 0.9em;
  padding: 12px 0;
}
.checkout-bttn:hover, .delete-bttn:focus, .delete-bttn:active  {
  background-color: #34006b !important;
}
.delete-bttn {
  padding: 9px 12px;
  background-color: #d41e45 !important;
  //font-size: 1em;
}
.delete-bttn:hover, .delete-bttn:focus, .delete-bttn:active  {
  background-color: #d41e45 !important;
}
@mixin chip($color, $textColor) {
  padding: 6px 12px;
  border-radius: 15px;
  font-weight: 600;
  font-size: 0.7em;
  background-color: $color !important;
  margin: 0 2px;
  color: $textColor;
}
.chip1 {
  @include chip(rgba(190, 152, 232, 1), white);
}
.chip2 {
  @include chip(rgba(236, 217, 255, 1), rgba(152, 71, 238, 1));
}
.smallHeading {
  font-size: 0.7em;
  font-weight: 600;
}
.line-color {
  border-right: 1px solid rgba(112, 112, 112, 1);
}

.cart-input {
  border: 1px solid #ecd9ff;
}

.cart-input::placeholder {
  // color: #BE98E8;
  font-size: 0.95rem;
}

.cart-drawer {
  border-bottom: 7px solid #fff;
}

.cart-item-selected {
  color: $seconday-color;
}
.fake-input-button {
  margin: 0;
  background-color: #be98e8 !important;
  border-radius: 3px;
  font-size: 0.7em;
  padding: 6px 9px;
  margin-left: 10px;
  color: white;
}
.attachment-name-right {
  right: 3px;
  top: 0;
  height: 100%;
}
.attachment-name-left {
  left: 4px;
  top: 0;
  height: 100%;
  width: calc(100% - 8px);
}
