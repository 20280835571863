.howItWork {
  width: 70%;
  max-width:700px;
  .videoContainer {
  padding:0;
    max-height:250px;
  }
}
.textContainer {
  background-color: #fff !important;
  box-sizing: border-box;
  h2 {
    margin-bottom: 0;
    font-weight:800;
  }
  p {
    font-size: clamp(0.9rem, 3vw, 0.75rem);
    line-height:1.2em;
    font-weight:500;
  }
}

.howItWork-textContainer{
  border-radius:0 !important ;
}
@media only screen and (max-width: 900px) {
  .howItWork {
    width: 100%;
    .textContainer {
      text-align: center;
      p {
        width: 90%;
      }
    }
    .videoContainer {
      height: clamp(25vh, 34vh, 50vh);
    }
  }
}
