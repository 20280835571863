.checkButton {
  background-color: rgba(218, 98, 125, 1) !important;
  font-weight: 500;
  font-size: 0.96em;
}
.checkButton:hover {
  background-color: rgba(218, 98, 125, 1) !important;
}

.checkButton:active, .checkButton:focus  {
  background-color: rgba(218, 98, 125, 1) !important;
}

.labelText {
  font-size: 0.7em;
  font-weight: bold;
}
.clickButton {
  color: rgba(218, 98, 125, 1);
  text-decoration: none;
  &:hover {
    color: rgba(218, 98, 125, 1);
  }
  &:active {
    color: rgba(218, 98, 125, 1);
  }
  &:focus {
    color: rgba(218, 98, 125, 1);
  }
}
@mixin Scndcolor($color) {
  color: $color;
}
.last-text {
  @include Scndcolor(rgba(128, 128, 128, 1));
}
.lastt-text {
  font-size: 0.8em;
  @include Scndcolor(rgba(128, 128, 128, 1));
}

.form-text {
  font-size: 0.9em;
  font-weight: 600;
  color: rgba(20, 23, 89, 1);
}
.form-text2 {
  font-size: 0.7em;

  color: rgba(20, 23, 89, 1);
}
.form-text3 {
  font-size: 0.8em;
  font-weight: 600;
  color: rgba(20, 23, 89, 1);
}
.tabs-title-container {
  list-style-type: none;
  border-bottom: 5px solid white;
  li {
    margin-right: 2em;
    text-transform: uppercase;
    padding-bottom: 5px;
    position: relative;
    font-size: 0.9em;
    font-weight: 600;
  }
  .tab-active {
    &::after {
      position: absolute;
      bottom: -5px;
      content: "";
      display: block;
      margin: 0;
      width: 110%;
      padding: 0;
      border-bottom: 5px solid $peachPink;
    }
  }
}
